import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, TextField, Button, Typography } from '@mui/material';
import logo from './saige.jpg'; 
import background from './background.png'; 

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    // temp allow any login to work by bypassing login server
    if (username && password) {
      navigate('/client-portal');
    } else {
      console.error('Login failed: Username and password cannot be empty');
    }
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding="48px"
        bgcolor="rgba(255, 255, 255, 0.8)"
        borderRadius="8px"
      >
        <img
          src={logo}
          alt="Logo"
          style={{
            marginBottom: '32px',
            width: '150px',
            height: '150px',
            borderRadius: '50%',
            objectFit: 'cover',
          }}
        />
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        <TextField
          label="Username"
          variant="outlined"
          margin="normal"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          margin="normal"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleLogin}
          style={{ marginTop: '16px' }}
        >
          Login
        </Button>
      </Box>
    </Container>
  );
};

export default LoginPage;

