import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './LoginPage';
import ClientPortal from './ClientPortal';
import RunReportPage from './RunReportPage'; 
import ViewReportsPage from './ViewReportsPage'; 

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/client-portal" element={<ClientPortal />} />
        <Route path="/run-report" element={<RunReportPage />} /> 
        <Route path="/view-reports" element={<ViewReportsPage />} /> 
      </Routes>
    </Router>
  );
};

export default App;


