import React, { useState } from 'react';
import {
  Container,
  Box,
  Button,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ReportIcon from '@mui/icons-material/Report';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import logo from './saige.jpg'; 
import background from './Grid 06.svg'; 

const ClientPortal = () => {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleRunReport = () => {
    navigate('/run-report');
  };

  const handleViewReports = () => {
    navigate('/view-reports');
  };

  const handleLogout = () => {
    navigate('/');
  };

  return (
    <Container
      maxWidth="sm"
      style={{
        backgroundColor: '#f0f4f8', 
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        color: '#333333',
      }}
    >
      <IconButton
        style={{
          position: 'absolute',
          top: '16px',
          left: '16px', 
          color: '#333333',
        }}
        onClick={toggleDrawer(true)}
      >
        <MenuIcon fontSize="large" />
      </IconButton>
      <IconButton
        style={{
          position: 'absolute',
          top: '16px',
          right: '16px',
          color: '#333333',
        }}
        onClick={() => navigate('/admin')}
      >
        <AccountCircleIcon fontSize="large" />
      </IconButton>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding="48px"
        bgcolor="rgba(255, 255, 255, 0.8)"
        borderRadius="8px"
        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
      >
        <img
          src={logo}
          alt="Logo"
          style={{
            marginBottom: '16px',
            width: '150px',
            height: '150px',
            borderRadius: '50%',
            objectFit: 'cover',
          }}
        />
        <Typography variant="h6" style={{ marginBottom: '32px' }}>
          Welcome to SAIGE, Ryan
        </Typography>
        <Button
          variant="contained"
          style={{
            marginBottom: '16px',
            backgroundColor: '#1e88e5',
            color: '#ffffff',
            width: '200px', 
          }}
          onClick={handleRunReport}
        >
          Run Report
        </Button>
        <Button
          variant="contained"
          style={{
            marginBottom: '16px',
            backgroundColor: '#1e88e5',
            color: '#ffffff',
            width: '200px', 
          }}
          onClick={handleViewReports}
        >
          View Reports
        </Button>
        <Button
          variant="contained"
          style={{
            backgroundColor: '#d32f2f',
            color: '#ffffff',
            width: '200px', 
          }}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </Box>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 250,
          }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem button onClick={handleRunReport}>
              <ListItemIcon>
                <ReportIcon />
              </ListItemIcon>
              <ListItemText primary="Run Report" />
            </ListItem>
            <ListItem button onClick={handleViewReports}>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary="View Reports" />
            </ListItem>
            <Divider />
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Container>
  );
};

export default ClientPortal;

