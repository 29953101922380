import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import DownloadIcon from '@mui/icons-material/Download';
import HomeIcon from '@mui/icons-material/Home';
import ReportIcon from '@mui/icons-material/Report';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import logo from './saige.jpg';
import './styles.css';

const ViewReportsPage = () => {
  const navigate = useNavigate();
  const [reports, setReports] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    fetch('https://saigeapi.patmosdata.com/api/reports/')
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setReports(data);
        } else {
          setReports([]);
          console.error('Unexpected data format:', data);
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleClientPortal = () => {
    navigate('/client-portal');
  };

  const handleRunReport = () => {
    navigate('/run-report');
  };

  const handleViewReports = () => {
    navigate('/view-reports');
  };

  const handleLogout = () => {
    navigate('/');
  };

  const getConfidenceLevel = (confidence) => {
    if (confidence >= 0 && confidence <= 40) {
      return {
        label: 'Low',
        color: 'red',
      };
    } else if (confidence >= 41 && confidence <= 65) {
      return {
        label: 'Medium',
        color: 'orange',
      };
    } else if (confidence >= 66) {
      return {
        label: 'High',
        color: 'green',
      };
    } else {
      return {
        label: 'Unknown',
        color: 'black',
      };
    }
  };

  const renderDetectionResults = (report) => {
    const { confidence, name } = report;
    const confidenceLevel = getConfidenceLevel(confidence * 100);

    return (
      <Box>
        <Typography style={{ fontWeight: 'bold' }}>
          SAIGE Shingle Analysis: {name || 'Unknown'}
        </Typography>
        <Typography style={{ fontWeight: 'bold', color: confidenceLevel.color }}>
          Confidence: {confidence ? `${(confidence * 100).toFixed(2)}% (${confidenceLevel.label})` : 'Unknown'}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      padding="20px"
      bgcolor="#f0f4f8"
      style={{ fontFamily: 'Clash Grotesk, sans-serif' }}
    >
      <IconButton
        style={{
          position: 'absolute',
          top: '16px',
          right: '16px',
          color: '#333333',
        }}
        onClick={toggleDrawer(true)}
      >
        <MenuIcon fontSize="large" />
      </IconButton>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          style: {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            zIndex: 1200,
            width: '240px',
            fontFamily: 'Clash Grotesk, sans-serif',
          },
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem button onClick={handleClientPortal}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText
                primary="Client Portal Main Page"
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'Clash Grotesk, sans-serif',
                }}
              />
            </ListItem>
            <Divider />
            <ListItem button onClick={handleRunReport}>
              <ListItemIcon>
                <ReportIcon />
              </ListItemIcon>
              <ListItemText
                primary="Run Report"
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'Clash Grotesk, sans-serif',
                }}
              />
            </ListItem>
            <ListItem button onClick={handleViewReports}>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText
                primary="View Reports"
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'Clash Grotesk, sans-serif',
                }}
              />
            </ListItem>
            <Divider />
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText
                primary="Logout"
                style={{
                  fontWeight: 'normal',
                  fontFamily: 'Clash Grotesk, sans-serif',
                }}
              />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      <img
        src={logo}
        alt="Logo"
        style={{
          marginBottom: '16px',
          width: '150px',
          height: '150px',
          borderRadius: '50%',
          objectFit: 'cover',
        }}
      />
      <Typography
        variant="h5"
        color="primary"
        gutterBottom
        style={{ fontWeight: 'normal', fontFamily: 'Clash Grotesk, sans-serif' }}
      >
        Completed Reports
      </Typography>

      <Box
        style={{
          width: '50%',
          maxHeight: '70vh', // Adjusted to make space for the header
          overflowY: 'auto',
          padding: '10px',
          backgroundColor: '#fff',
          borderRadius: '16px',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
          marginTop: '20px', // Add space between header and content
        }}
      >
        {reports.length === 0 ? (
          <Card style={{ marginBottom: '20px' }}>
            <CardContent>
              <Typography
                variant="h6"
                align="center"
                style={{ fontWeight: 'normal', fontFamily: 'Clash Grotesk, sans-serif' }}
              >
                No completed reports found
              </Typography>
            </CardContent>
          </Card>
        ) : (
          reports.map((report) => (
            <Card
              key={report._id}
              style={{
                marginBottom: '20px',
                borderRadius: '12px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <CardContent>
                <Box>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 'bold', fontFamily: 'Clash Grotesk, sans-serif' }}
                  >
                    Report ID: {report._id}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    style={{ fontWeight: 'normal', fontFamily: 'Clash Grotesk, sans-serif' }}
                  >
                    Image Name: {report.image_name}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    style={{ fontWeight: 'normal', fontFamily: 'Clash Grotesk, sans-serif' }}
                  >
                    Image ID: {report.image_id}
                  </Typography>
                  {renderDetectionResults(report)}
                </Box>
                <IconButton aria-label="download report" style={{ marginTop: '10px' }}>
                  <DownloadIcon />
                  <Typography
                    style={{ fontWeight: 'normal', fontFamily: 'Clash Grotesk, sans-serif' }}
                  >
                    Download Report
                  </Typography>
                </IconButton>
              </CardContent>
            </Card>
          ))
        )}
      </Box>
    </Box>
  );
};

export default ViewReportsPage;


