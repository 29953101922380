import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ReportIcon from '@mui/icons-material/Report';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HomeIcon from '@mui/icons-material/Home';
import axios from 'axios';
import logo from './saige.jpg';
import MuiAlert from '@mui/material/Alert';

const RunReportPage = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [photo, setPhoto] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: '', severity: '' });

  const handleFileChange = (event) => {
    setPhoto(event.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!photo) {
      setNotification({ open: true, message: 'Please select a photo to upload.', severity: 'warning' });
      return;
    }

    const formData = new FormData();
    formData.append('file', photo);

    setLoading(true);

    try {
      const response = await axios.post('https://saigeapi.patmosdata.com/upload/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true, // Include credentials if necessary
      });

      if (response.status === 200) {
        const { filename, results } = response.data;

        console.log('File uploaded:', filename);
        console.log('Detection results:', results);

        setIsSubmitted(true);
        setNotification({ open: true, message: 'Report submitted successfully!', severity: 'success' });
      } else {
        throw new Error('Unexpected response status: ' + response.status);
      }
    } catch (error) {
      console.error('Error uploading data', error);
      setNotification({ open: true, message: `Error submitting report: ${error.message}`, severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      const timer = setTimeout(() => {
        navigate('/client-portal');
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isSubmitted, navigate]);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleRunReport = () => {
    navigate('/run-report');
  };

  const handleViewReports = () => {
    navigate('/view-reports');
  };

  const handleLogout = () => {
    navigate('/');
  };

  const handleClientPortal = () => {
    navigate('/client-portal');
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  return (
    <Container>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 250,
          }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem button onClick={handleClientPortal}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Client Portal Main Page" />
            </ListItem>
            <Divider />
            <ListItem button onClick={handleRunReport}>
              <ListItemIcon>
                <ReportIcon />
              </ListItemIcon>
              <ListItemText primary="Run Report" />
            </ListItem>
            <ListItem button onClick={handleViewReports}>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary="View Reports" />
            </ListItem>
            <Divider />
            <ListItem button onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      {isSubmitted ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Typography variant="h4" color="primary" gutterBottom>
            Report submitted successfully!
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Redirecting to the main screen in 5 seconds...
          </Typography>
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          bgcolor="#f0f4f8"
          padding="20px"
          boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
          borderRadius="8px"
          maxWidth="50%"
          margin="auto"
          position="relative"
        >
          <IconButton
            style={{
              position: 'absolute',
              top: '16px',
              right: '16px',
              color: '#333333',
            }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon fontSize="large" />
          </IconButton>

          {/* Logo */}
          <img
            src={logo}
            alt="Logo"
            style={{
              marginBottom: '16px',
              width: '150px',
              height: '150px',
              borderRadius: '50%',
              objectFit: 'cover',
            }}
          />

          <Typography variant="h4" color="primary" gutterBottom>
            Run SAIGE ID Report
          </Typography>
          <TextField
            label="Property Address"
            variant="outlined"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Homeowner Name"
            variant="outlined"
            value={ownerName}
            onChange={(e) => setOwnerName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <input
            type="file"
            onChange={handleFileChange}
            accept="image/*"
            style={{ marginTop: '20px', marginBottom: '20px' }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ marginTop: '20px' }}
            disabled={loading}
          >
            Submit Report
          </Button>
          {loading && (
            <CircularProgress
              style={{ marginTop: '20px', color: 'primary' }}
              size={50}
            />
          )}
        </Box>
      )}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
      >
        <MuiAlert
          onClose={handleCloseNotification}
          severity={notification.severity}
          elevation={6}
          variant="filled"
        >
          {notification.message}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
};

export default RunReportPage;
